import React from "react"
import "./contact-header.scss"
import { CircleArrow, EmailIcon } from "../elements/svg-elements"
import { ContactHeaderProps } from "../../types/components"

const ContactHeader: React.FC<ContactHeaderProps> = ({
  pageTitle,
  title,
  subtitle,
  email,
}) => {
  return (
    <div className="contact-header">
      <div className="contact-header__wrapper container--xl">
        <div className="contact-header__title-wrapper">
          <h1
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-easing="ease-out"
          >
            {pageTitle}
          </h1>
        </div>
        <div className="contact-header__items">
          <div className="contact-header__items__empty half--left" />
          <div className="contact-header__items__text-nodes half--right">
            <h3
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-easing="ease-out"
            >
              {title}
            </h3>
            <h4
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-easing="ease-out"
            >
              {subtitle}
            </h4>
            <a
              href={`mailto:${email}`}
              data-aos="fade-up"
              data-aos-delay="250"
              data-aos-easing="ease-out"
            >
              <EmailIcon />
              {email}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactHeader
