import React from "react"
import { graphql, PageProps } from "gatsby"
import { ContactPageProps } from "../types/pages"
import PageLayout from "../layouts/page-layout"
import ContactHeader from "../components/contact/contact-header"
import MapLocation from "../components/contact/map-location"
import { MapLocationProps } from "../types/components"

const Contact: React.FC<PageProps<ContactPageProps>> = ({ data }) => {
  const { contactInfo, locations } = data?.PAGE_CONTENT?.contactOptions

  return (
    <PageLayout seo={data?.PAGE_METADATA?.seo}>
      <div className="page--container">
        <ContactHeader
          pageTitle={data?.PAGE_METADATA?.title}
          title={contactInfo.title}
          email={contactInfo.email}
          subtitle={contactInfo.subtitle}
        />
        {locations &&
          locations.map((location: MapLocationProps, index: number) => (
            <MapLocation
              key={index}
              index={index + 1}
              address={location.address}
              locationImage={location.locationImage}
              name={location.name}
            />
          ))}
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  query ($uri: String!, $locale: String!) {
    PAGE_METADATA: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      id
      title
      locale {
        locale
      }
      seo {
        metaDesc
        metaKeywords
        canonical
        opengraphDescription
        opengraphTitle
        opengraphType
        title
        twitterDescription
        twitterTitle
      }
    }
    PAGE_CONTENT: wpPage(
      uri: { eq: $uri }
      locale: { locale: { eq: $locale } }
    ) {
      contactOptions {
        contactInfo {
          email
          subtitle
          title
        }
        locations {
          address
          name
          locationImage {
            altText
            sizes
            sourceUrl
            srcSet
          }
        }
      }
    }
  }
`

export default Contact
