import React, { memo } from "react"
import "./map-location.scss"
import { MapLocationProps } from "../../types/components"
import Img from "../shared/img"

const MapLocation: React.FC<MapLocationProps> = ({
  address,
  locationImage,
  name,
  index,
}) => {
  return (
    <div className="map-location">
      <div className="map-location__wrapper container--xl">
        <div className="map-location__address half--left">
          <h3
            data-aos="fade-up"
            data-aos-delay={index && index * 100}
            data-aos-easing="ease-out"
          >
            {name}
          </h3>
          <div
            dangerouslySetInnerHTML={{ __html: address }}
            data-aos="fade-up"
            data-aos-delay={index && index * 120}
            data-aos-easing="ease-out"
          />
        </div>
        <div className="map-location__map half--right">
          <Img
            className="map-location__map__image"
            imgProps={locationImage}
            delay={index && index * 100}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(MapLocation)
